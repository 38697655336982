<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div>
    <v-simple-table v-if="!isLoading">
      <template v-slot:default>
        <tbody>
          <!--PRODUCTS-->
          <tr>
            <th>
              {{ $t('lbl.product') }}
            </th>
            <th class="text-right">
              <v-btn
                icon
                @click="showItemProduct()"
              >
                <v-icon>
                  {{ showRowProduct ? icons.mdiMenuDown : icons.mdiMenuRight }}
                </v-icon>
              </v-btn>
            </th>
          </tr>
          <fragment v-if="showRowProduct">
            <tr>
              <th class="text-uppercase">
                <b>{{ $t('lbl.identificador') }}</b>
              </th>

              <th
                v-for="(afiliado, j) in typeAfiliados"
                :key="j"
                class="text-center"
                width="280px"
              >
                <b>{{ afiliado.name }}</b>
              </th>
            </tr>

            <tr
              v-for="(mark, indM) in itemsProduct"
              :key="indM"
            >
              <td>
                {{ mark.identificador }}
              </td>
              <td
                v-for="(afiliado, j) in typeAfiliados"
                :key="j"
                class="text-center"
              >
                <span
                  v-for="(dataAfil, indAd) in mark.data_sale_afiliate"
                  :key="indAd"
                >
                  <span v-if="dataAfil.type_id === afiliado.id">
                    <span v-if="dataAfil.value">
                      <span v-if="!editMarkup">
                        {{
                          `${dataAfil.is_value ? '$' : ''}
                          ${dataAfil.value}
                          ${!dataAfil.is_value ? '%' : ''}`
                        }}
                      </span>
                      <span v-else>
                        <v-row>
                          <v-col cols="5">
                            <v-text-field
                              v-model="dataAfil.value"
                              label="Markup"
                              type="number"
                              hide-details
                              dense
                              outlined
                              style="width: 80px"
                              :prefix="dataAfil.is_value ? '$' : ''"
                              :suffix="!dataAfil.is_value ? '%' : ''"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="2">
                            <v-switch
                              v-model="dataAfil.is_value"
                              :label="$t('lbl.valor')"
                              hide-details
                              class="mt-2"
                            ></v-switch>
                          </v-col>
                        </v-row>
                      </span>
                    </span>
                  </span>
                </span>
              </td>
            </tr>
          </fragment>

          <tr>
            <th
              :colspan="2"
              style="height: 10px"
            ></th>
          </tr>
          <!--PROVEEDOR-->
          <tr>
            <th>
              {{ $t('lbl.proveedor') }}
            </th>
            <th class="text-right">
              <v-btn
                icon
                @click="showItemProveedor()"
              >
                <v-icon>
                  {{ showRowProveedor ? icons.mdiMenuDown : icons.mdiMenuRight }}
                </v-icon>
              </v-btn>
            </th>
          </tr>
          <fragment v-if="showRowProveedor">
            <tr>
              <th class="text-uppercase">
                <b>{{ $t('lbl.identificador') }}</b>
              </th>

              <th
                v-for="(afiliado, j) in typeAfiliados"
                :key="j"
                class="text-center"
                width="280px"
              >
                <b>{{ afiliado.name }}</b>
              </th>
            </tr>

            <tr
              v-for="(mark, indM) in itemsProveedor"
              :key="indM"
            >
              <td>
                {{ mark.identificador }}
              </td>
              <td
                v-for="(afiliado, j) in typeAfiliados"
                :key="j"
                class="text-center"
                width="280px"
              >
                <span
                  v-for="(dataAfil, indAd) in mark.data_sale_afiliate"
                  :key="indAd"
                >
                  <span v-if="dataAfil.type_id === afiliado.id">
                    <span v-if="dataAfil.value">
                      <span v-if="!editMarkup">
                        {{
                          `${dataAfil.is_value ? '$' : ''}
                          ${dataAfil.value}
                          ${!dataAfil.is_value ? '%' : ''}`
                        }}
                      </span>
                      <span v-else>
                        <v-row>
                          <v-col cols="5">
                            <v-text-field
                              v-model="dataAfil.value"
                              label="Markup"
                              type="number"
                              hide-details
                              dense
                              outlined
                              style="width: 80px"
                              :prefix="dataAfil.is_value ? '$' : ''"
                              :suffix="!dataAfil.is_value ? '%' : ''"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="2">
                            <v-switch
                              v-model="dataAfil.is_value"
                              :label="$t('lbl.valor')"
                              hide-details
                              class="mt-2"
                            ></v-switch>
                          </v-col>
                        </v-row>
                      </span>
                    </span>
                  </span>
                </span>
              </td>
            </tr>
          </fragment>

          <tr>
            <th
              :colspan="2"
              style="height: 10px"
            ></th>
          </tr>
          <!--CONTRATE-->
          <tr>
            <th>
              {{ $t('lbl.contrato') }}
            </th>

            <th class="text-right">
              <v-btn
                icon
                @click="showItemContrate()"
              >
                <v-icon>
                  {{ showRowContrate ? icons.mdiMenuDown : icons.mdiMenuRight }}
                </v-icon>
              </v-btn>
            </th>
          </tr>
          <fragment v-if="showRowContrate">
            <tr>
              <th class="text-uppercase">
                <b>{{ $t('lbl.identificador') }}</b>
              </th>

              <th
                v-for="(afiliado, j) in typeAfiliados"
                :key="j"
                class="text-center"
                width="280px"
              >
                <b>{{ afiliado.name }}</b>
              </th>
            </tr>

            <tr
              v-for="(mark, indM) in itemsContrate"
              :key="indM"
            >
              <td>
                {{ mark.identificador }}
              </td>
              <td
                v-for="(afiliado, j) in typeAfiliados"
                :key="j"
                class="text-center"
              >
                <span
                  v-for="(dataAfil, indAd) in mark.data_sale_afiliate"
                  :key="indAd"
                >
                  <span v-if="dataAfil.type_id === afiliado.id">
                    <span v-if="dataAfil.value">
                      <span v-if="!editMarkup">
                        {{
                          `${dataAfil.is_value ? '$' : ''}
                          ${dataAfil.value}
                          ${!dataAfil.is_value ? '%' : ''}`
                        }}
                      </span>
                      <span v-else>
                        <v-row>
                          <v-col cols="5">
                            <v-text-field
                              v-model="dataAfil.value"
                              label="Markup"
                              type="number"
                              hide-details
                              dense
                              outlined
                              style="width: 80px"
                              :prefix="dataAfil.is_value ? '$' : ''"
                              :suffix="!dataAfil.is_value ? '%' : ''"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="2">
                            <v-switch
                              v-model="dataAfil.is_value"
                              :label="$t('lbl.valor')"
                              hide-details
                              class="mt-2"
                            ></v-switch>
                          </v-col>
                        </v-row>
                      </span>
                    </span>
                  </span>
                </span>
              </td>
            </tr>
          </fragment>

          <tr>
            <th
              :colspan="2"
              style="height: 10px"
            ></th>
          </tr>
          <!--VUELOS-->
          <tr>
            <th>
              {{ $t('lbl.vuelo') }}
            </th>
            <th class="text-right">
              <v-btn
                icon
                @click="showItemFlight()"
              >
                <v-icon>
                  {{ showRowFlight ? icons.mdiMenuDown : icons.mdiMenuRight }}
                </v-icon>
              </v-btn>
            </th>
          </tr>
          <fragment v-if="showRowFlight">
            <tr>
              <th class="text-uppercase">
                <b>{{ $t('lbl.identificador') }}</b>
              </th>

              <th
                v-for="(afiliado, j) in typeAfiliados"
                :key="j"
                class="text-center"
                width="280px"
              >
                <b>{{ afiliado.name }}</b>
              </th>
            </tr>

            <tr
              v-for="(mark, indM) in itemsFlights"
              :key="indM"
            >
              <td>
                {{ mark.identificador }}
              </td>
              <td
                v-for="(afiliado, j) in typeAfiliados"
                :key="j"
                class="text-center"
              >
                <span
                  v-for="(dataAfil, indAd) in mark.data_sale_afiliate"
                  :key="indAd"
                >
                  <span v-if="dataAfil.type_id === afiliado.id">
                    <span v-if="dataAfil.value">
                      <span v-if="!editMarkup">
                        {{
                          `${dataAfil.is_value ? '$' : ''}
                          ${dataAfil.value}
                          ${!dataAfil.is_value ? '%' : ''}`
                        }}
                      </span>
                      <span v-else>
                        <v-row>
                          <v-col cols="5">
                            <v-text-field
                              v-model="dataAfil.value"
                              label="Markup"
                              type="number"
                              hide-details
                              dense
                              outlined
                              style="width: 80px"
                              :prefix="dataAfil.is_value ? '$' : ''"
                              :suffix="!dataAfil.is_value ? '%' : ''"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="2">
                            <v-switch
                              v-model="dataAfil.is_value"
                              :label="$t('lbl.valor')"
                              hide-details
                              class="mt-2"
                            ></v-switch>
                          </v-col>
                        </v-row>
                      </span>
                    </span>
                  </span>
                </span>
              </td>
            </tr>
          </fragment>

          <!--<tr>
            <th
              :colspan="2"
              style="height: 10px"
            ></th>
          </tr>-->
          <!--TEMPORADAS
          <tr>
            <th>
              {{ $t('lbl.temporadas') }}
            </th>

            <th class="text-right">
              <v-btn
                icon
                @click="showItemTemporada()"
              >
                <v-icon>
                  {{ showRowTemporada ? icons.mdiMenuDown : icons.mdiMenuRight }}
                </v-icon>
              </v-btn>
            </th>
          </tr>
          <fragment v-if="showRowTemporada">
            <tr>
              <th class="text-uppercase">
                <b>{{ $t('lbl.identificador') }}</b>
              </th>

              <th
                v-for="(afiliado, j) in typeAfiliados"
                :key="j"
                class="text-center"
width="280px"
              >
                <b>{{ afiliado.name }}</b>
              </th>

            </tr>

            <tr
              v-for="(mark, indM) in itemsTemporada"
              :key="indM"
            >
              <td>
                {{ mark.identificador }}
              </td>
              <td>
                <span v-if="mark.data_sale_fisic.value">
                  {{
                    `${mark.data_sale_fisic.is_value ? '$' : ''}
                    ${mark.data_sale_fisic.value}
                    ${!mark.data_sale_fisic.is_value ? '%' : ''}`
                  }}
                </span>
              </td>
              <td>
                <span v-if="mark.data_sale_online.value">
                  {{
                    `${mark.data_sale_online.is_value ? '$' : ''}
                    ${mark.data_sale_online.value}
                    ${!mark.data_sale_online.is_value ? '%' : ''}`
                  }}
                </span>
              </td>
              <td
                v-for="(afiliado, j) in typeAfiliados"
                :key="j"
                class="text-center"
width="280px"
              >
                <fragment
                  v-for="(dataAfil, indAd) in mark.data_sale_afiliate"
                  :key="indAd"
                >
                  <fragment v-if="dataAfil.type_id === afiliado.id">
                    <span v-if="dataAfil.value">
                      <fragment v-if="!editMarkup">
                        {{
                          `${dataAfil.is_value ? '$' : ''}
                          ${dataAfil.value}
                          ${!dataAfil.is_value ? '%' : ''}`
                        }}
                      </fragment>
                      <fragment v-else>
                        <v-row>
                          <v-col cols="5">
                            <v-text-field
                              v-model="dataAfil.value"
                              label="Markup"
                              type="number"
                              hide-details
                              dense
                              outlined
                              style="width: 80px"
                              :prefix="dataAfil.is_value ? '$' : ''"
                              :suffix="!dataAfil.is_value ? '%' : ''"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="2">
                            <v-switch
                              v-model="dataAfil.is_value"
                              :label="$t('lbl.valor')"
                              hide-details
                              class="mt-2"
                            ></v-switch>
                          </v-col>
                        </v-row>
                      </fragment>
                    </span>
                  </fragment>
                </fragment>
              </td>
              <td
                v-if="permisos.includes('markup:edit') || permisos.includes('markup:destroy') || permisos.includes('*')"
              >
                <v-tooltip
                  v-if="permisos.includes('markup:edit') || permisos.includes('*')"
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="editItem(mark)"
                    >
                      <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.edit') }}</span>
                </v-tooltip>
                <v-tooltip
                  v-if="permisos.includes('markup:destroy') || permisos.includes('*')"
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="deleteItem(mark)"
                    >
                      <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.delete') }}</span>
                </v-tooltip>
              </td>
            </tr>
          </fragment>-->
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyePlus,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiFormatListText,
  mdiEraserVariant,
  mdiArrowUp,
  mdiArrowDown,
  mdiMenuDown,
  mdiMenuRight,
  mdiEyeCheck,
} from '@mdi/js'

export default {
  props: {
    isComition: {
      type: Boolean,
    },
    // eslint-disable-next-line vue/require-default-prop
    proveedores: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    contrates: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    flights: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    typeAfiliados: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    editMarkup: {
      type: Boolean,
    },
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object,
    },
  },

  data() {
    return {
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      search: '',
      items: [],
      pagination: {
        current: 1,
        total: 0,
      },
      totalItems: 0,
      totalF: 0,

      showRowProduct: false,
      showRowProveedor: false,
      showRowContrate: false,
      showRowFlight: false,
      showRowTemporada: false,
      showRowDate: false,

      // itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyePlus,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiFormatListText,
        mdiEraserVariant,
        mdiArrowUp,
        mdiArrowDown,
        mdiMenuDown,
        mdiMenuRight,
        mdiEyeCheck,
      },
      isDialogVisible: false,
      model: {},
      itemsOrigen: [],
      searchOrigen: null,
      itemsDestino: [],
      searchDestino: null,
      loading: false,

      showRow: false,
      indexRow: -1,

      aerolineas: [],

      item: {},
      itemId: null,
      isDialogVisibleDelete: false,
      abilities: [],
      abilityList: [],
      checkPermiso: 1,
      isLoad: true,
      permisos: sessionStorage.getItem('permisos_auth'),
    }
  },
  computed: {
    ...mapState({
      markupsList: state => state.app.markupsList,
    }),
    itemsProduct() {
      if (this.markupsList.length > 0) {
        const newArr = []
        this.markupsList.forEach(element => {
          if (
            // eslint-disable-next-line eqeqeq
            element.is_comition == this.isComition
            && (element.products.includes('flights') || element.products.includes('all'))
          ) {
            let identificador = ''
            if (element.products.includes('all')) {
              identificador = 'Vuelos'

              // identificador = 'Todos'
            } else {
              identificador = 'Vuelos'

              /* if (element.products.includes('hotels')) {
                identificador += ', Hoteles'
              }
              if (element.products.includes('flights')) {
                identificador += ', Vuelos'
              } */
            }

            if (
              (element.proveedor_id.length === 0
                || (element.proveedor_id.length === 1 && element.proveedor_id.includes(-1)))
              && element.contrate_id.length === 0
              && element.flight_id.length === 0

            // && element.temporada_id.length === 0
            ) {
              newArr.push({
                identificador,
                ...element,
              })
            }
          }
        })

        return newArr
      }

      return []
    },
    itemsProveedor() {
      if (this.markupsList.length > 0) {
        const newArr = []
        this.markupsList.forEach(element => {
          if (
            element.is_comition === this.isComition
            && (element.products.includes('flights')
              || element.products.includes('vuelos')
              || element.products.includes('all'))
          ) {
            if (
              element.proveedor_id.length > 0
              && !element.proveedor_id.includes(-1)
              && ((element.contrate_id.length === 1 && element.contrate_id.includes(-1))
                || element.contrate_id.length === 0)
              && element.flight_id.length === 0

            // && element.temporada_id.length === 0
            ) {
              element.proveedor_id.forEach(provee => {
                let identificador = ''
                const ppp = this.proveedores.filter(e => e.id === provee)
                if (ppp.length > 0) {
                  identificador = ppp[0].name_comercial
                } else {
                  identificador = 'Todos'
                }
                newArr.push({
                  identificador,
                  ...element,
                })
              })
            }
          }
        })

        return newArr
      }

      return []
    },
    itemsContrate() {
      if (this.markupsList.length > 0) {
        const newArr = []
        this.markupsList.forEach(element => {
          if (
            // eslint-disable-next-line eqeqeq
            element.is_comition == this.isComition
            && (element.products.includes('flights') || element.products.includes('all'))
          ) {
            if (
              element.proveedor_id.length === 1
              && element.contrate_id.length > 0
              && !element.contrate_id.includes(-1)
              && element.flight_id.length === 1
              && element.flight_id.includes(-1)

            // && element.temporada_id.length === 1
            // && element.temporada_id.includes('all')
            ) {
              element.contrate_id.forEach(ite => {
                let identificador = ''
                const ppp = this.contrates.filter(e => e.id === ite)
                if (ppp.length > 0) {
                  identificador = ppp[0].identificador
                } else {
                  identificador = 'Todos'
                }
                newArr.push({
                  identificador,
                  ...element,
                })
              })
            }
          }
        })

        return newArr
      }

      return []
    },
    itemsFlights() {
      if (this.markupsList.length > 0) {
        const newArr = []
        this.markupsList.forEach(element => {
          if (
            // eslint-disable-next-line eqeqeq
            element.is_comition == this.isComition
            && (element.products.includes('flights') || element.products.includes('all'))
          ) {
            if (
              element.proveedor_id.length === 1
              && !element.proveedor_id.includes(-1)
              && element.contrate_id.length === 1
              && !element.contrate_id.includes(-1)
              && element.flight_id.length > 0
              && !element.flight_id.includes(-1)

            // && element.temporada_id.length === 1
            // && element.temporada_id.includes('all')
            ) {
              element.flight_id.forEach(ite => {
                let identificador = ''
                const cont = this.contrates.filter(e => e.id === element.contrate_id[0])
                if (cont.length > 0) {
                  identificador = cont[0].identificador
                }

                const ppp = this.flights.filter(e => e.id === ite)
                if (ppp.length > 0) {
                  identificador += `, ${ppp[0].numero_de_vuelo}`
                } else {
                  identificador += ', Todos'
                }
                newArr.push({
                  identificador,
                  ...element,
                })
              })
            }
          }
        })

        return newArr
      }

      return []
    },
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false
    }, 100)

    // this.loadAll()
  },
  methods: {
    ...mapMutations(['updateMarkupsList', 'deleteMarkupsList']),
    showItemProduct() {
      this.showRowProduct = !this.showRowProduct
    },
    showItemProveedor() {
      this.showRowProveedor = !this.showRowProveedor
    },
    showItemContrate() {
      this.showRowContrate = !this.showRowContrate
    },
    showItemFlight() {
      this.showRowFlight = !this.showRowFlight
    },
    showItemTemporada() {
      this.showRowTemporada = !this.showRowTemporada
    },
    showItemDate() {
      this.showRowDate = !this.showRowDate
    },
    loadAll() {
      const json = {
        page: this.pagination.current,
        per_page: this.$store.state.app.itemsPerPage,
        nom_tipo_product_slug: 'vuelos',
        perfil_slug: sessionStorage.getItem('perfil'),
      }
      this.axios
        .post('markups/list', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.totalItems = res.data.meta.total
          }
        })
    },
    editItem(mark) {
      this.$emit('asign:markups', mark.id)
      window.scrollTo(0, 0)
    },
    deleteItem(iten) {
      this.isDialogVisibleDelete = true
      this.item = iten
      this.itemId = iten.id
    },
    confirmDelete() {
      this.loading = true
      this.axios
        .delete(`markups/${this.itemId}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            if (res.data.data.status === 401) {
              // this.$toast.error(this.$t('msg.noAutorice'))
            }
          } else {
            this.$toast.success(this.$t('msg.infoSuccess'))
            this.$emit('load')
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => this.reseat())
    },
    reseat() {
      this.loading = false
      this.isDialogVisibleDelete = false
    },

    showItem(index) {
      if (this.indexRow === index) {
        this.showRow = !this.showRow
      } else if (!this.showRow) {
        this.showRow = !this.showRow
      }
      this.indexRow = index
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
