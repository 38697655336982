<template>
  <v-row class="mt-2">
    <v-col
      cols="12"
      md="12"
    >
      <h3>
        {{ $t('lbl.contacts') }}
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ml-5"
              fab
              dark
              x-small
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="addContact()"
            >
              <v-icon small>
                {{ icons.mdiPlus }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('btn.insert') }}</span>
        </v-tooltip>
      </h3>
    </v-col>
    <v-col
      cols="12"
      md="12"
    >
      <ExecutiveOne
        v-for="(item, indC) in model.executives"
        :key="indC"
        :pos="indC"
        :model="model"
        :executives="model.executives"
        :executive="item"
        :rols="rols"
        :users="users"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
} from '@mdi/js'
import { v4 as uuidv4 } from 'uuid'
import ExecutiveOne from './ExecutiveOne.vue'

export default {
  components: {
    ExecutiveOne,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    model: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    executives: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    rols: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    users: {
      type: Array,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
      },
      loading: false,
      permisos: sessionStorage.getItem('permisos_auth'),
    }
  },
  methods: {
    ...mapMutations(['deleteContactsAfiliados']),
    addContact() {
      this.model.executives.push({
        id: uuidv4(),
        user_id: null,
        name: null,
        apellidos: null,
        role_id: null,
        email: null,
        phones: [
          {
            code: null,
            phone: null,
            ext: null,
            exts: [],
          },
        ],
      })
    },
  },
}
</script>
<style scoped>
::v-deep .my-input .v-label,
::v-deep .my-input input {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
::v-deep .v-text-field__details {
  padding: 0 0 0 0;
}
</style>
