var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLoading)?_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-select',{attrs:{"items":_vm.typeAfiliados,"item-text":"name","item-value":"id","label":_vm.$t('menu.typeAfiliado'),"outlined":"","dense":"","hide-details":""},model:{value:(_vm.model.tipo_afiliado_id),callback:function ($$v) {_vm.$set(_vm.model, "tipo_afiliado_id", $$v)},expression:"model.tipo_afiliado_id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[(!_vm.editMarkup)?_c('v-btn',{staticClass:"mb-4 me-3",attrs:{"color":"primary","disabled":!_vm.model.tipo_afiliado_id},on:{"click":function($event){_vm.editMarkup = !_vm.editMarkup}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiSquareEditOutline))]),_c('span',[_vm._v(_vm._s(_vm.$t('btn.edit')))])],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[(_vm.model.tipo_afiliado_id)?_c('v-tabs',{model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[(_vm.productsPermit.includes('vuelos') || _vm.productsPermit.includes('flights'))?_c('v-tab',[_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-airplane")])],1)]}}],null,false,1500370249)},[_c('span',[_vm._v(_vm._s(_vm.$t('menu.flights')))])])],1):_vm._e(),(_vm.productsPermit.includes('cars'))?_c('v-tab',[_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-car")])],1)]}}],null,false,387884341)},[_c('span',[_vm._v(_vm._s(_vm.$t('menu.cars')))])])],1):_vm._e(),(_vm.productsPermit.includes('hotels'))?_c('v-tab',[_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-office-building")])],1)]}}],null,false,2368689406)},[_c('span',[_vm._v(_vm._s(_vm.$t('menu.hotels')))])])],1):_vm._e()],1):_vm._e(),_c('v-divider'),(_vm.model.tipo_afiliado_id)?_c('v-tabs-items',{model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[(_vm.productsPermit.includes('vuelos') || _vm.productsPermit.includes('flights'))?_c('v-tab-item',[(_vm.showTabs)?_c('FlightsTab',{attrs:{"is-comition":false,"proveedores":_vm.proveedores,"contrates":_vm.contrates,"flights":_vm.flights,"type-afiliados":_vm.user.nivel !== 2 ? _vm.typeAfiliados.filter(function (e) { return e.id === _vm.model.tipo_afiliado_id; }) : [],"edit-markup":_vm.editMarkup,"user":_vm.user}}):_vm._e()],1):_vm._e(),(_vm.productsPermit.includes('cars'))?_c('v-tab-item',[(_vm.showTabs)?_c('CarsTab',{attrs:{"is-comition":false,"proveedores-car":_vm.proveedoresCar,"contrates-car":_vm.contratesCar,"categories-car":_vm.categoriesCar,"marcas-car":_vm.marcasCar,"type-afiliados":_vm.user.nivel !== 2 ? _vm.typeAfiliados.filter(function (e) { return e.id === _vm.model.tipo_afiliado_id; }) : [],"edit-markup":_vm.editMarkup,"user":_vm.user}}):_vm._e()],1):_vm._e(),(_vm.productsPermit.includes('hotels'))?_c('v-tab-item',[(_vm.showTabs)?_c('HotelsTab',{attrs:{"is-comition":false,"proveedores-hotel":_vm.proveedoresHotel,"contrates-hotel":_vm.contratesHotel,"hotels":_vm.hotels,"rooms":_vm.rooms,"type-afiliados":_vm.user.nivel !== 2 ? _vm.typeAfiliados.filter(function (e) { return e.id === _vm.model.tipo_afiliado_id; }) : [],"edit-markup":_vm.editMarkup,"user":_vm.user}}):_vm._e()],1):_vm._e()],1):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }