<template>
  <v-row class="mt-2">
    <v-col
      v-for="(product, i) in products"
      :key="i"
      cols="12"
      md="2"
    >
      <v-card
        color="text-center"
        :disabled="product.slug !== 'cars' && product.slug !== 'hotels' && product.slug !== 'vuelos'"
      >
        <v-card-text>
          <v-icon
            v-if="product.icon"
            size="2rem"
            :color="
              product.slug !== 'cars' && product.slug !== 'hotels' && product.slug !== 'vuelos' ? 'default' : 'primary'
            "
          >
            {{ product.icon }}
          </v-icon>
          <h3 class="mt-4">
            {{ product.name }}
          </h3>
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="4"
              offset-md="4"
            >
              <v-checkbox
                v-model="checkProducts"
                label=""
                :value="product.id"
                @click="setProducts"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    model: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    products: {
      type: Array,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      checkProducts: [],
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
      },
      loading: false,
      permisos: sessionStorage.getItem('permisos_auth'),
    }
  },
  created() {
    if (this.model.products.length > 0) {
      this.model.products.forEach(element => {
        this.checkProducts.push(element)
      })
    }
  },
  methods: {
    ...mapMutations(['deleteContactsAfiliados']),
    setProducts() {
      this.model.products = this.checkProducts
    },
  },
}
</script>
<style scoped>
::v-deep .my-input .v-label,
::v-deep .my-input input {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
::v-deep .v-text-field__details {
  padding: 0 0 0 0;
}
</style>
