<template>
  <v-row class="mt-2">
    <v-col
      cols="12"
      md="4"
    >
      <v-radio-group
        v-model="model.method_pay_id"
        class="mt-0 pt-0"
        mandatory
        row
      >
        <v-col
          v-for="(method, index) in methodPays"
          :key="index"
          cols="12"
          md="6"
        >
          <v-card color="text-center">
            <v-card-text class="d-flex flex-column justify-center align-center">
              <v-icon
                size="2rem"
                color="primary"
              >
                {{ method.slug === 'prepago' ? 'mdi-credit-card-check-outline' : 'mdi-credit-card-refresh-outline' }}
              </v-icon>
              <h3 class="mt-4">
                {{ method.name }}
              </h3>
            </v-card-text>
            <v-card-text style="text-align: center;">
              <v-radio
                class="justify-center align-center"
                label=""
                :value="method.id"
                @click="changeMethodPay(method)"
              ></v-radio>
            </v-card-text>
          </v-card>
        </v-col>
      </v-radio-group>
    </v-col>

    <v-col
      cols="12"
      md="6"
    >
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <v-radio-group
            v-model="model.type_method_pay_id"
            row
          >
            <v-radio
              v-for="(type, index) in typesMethodPays"
              :key="index"
              :label="type.name"
              :value="type.id"
              @click="changeTypeMethod(type)"
            ></v-radio>
          </v-radio-group>
        </v-col>
        <v-col
          v-if="showMonto"
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="model.data_method_pay.monto"
            prefix="$"
            :label="$t('lbl.monto')"
            outlined
            dense
            hide-details
            type="number"
          ></v-text-field><br />
        </v-col>
        <v-col
          v-if="showTime"
          cols="12"
          md="4"
        >
          <v-select
            v-model="model.data_method_pay.time"
            :items="times"
            item-text="name"
            item-value="id"
            :label="$t('lbl.time')"
            outlined
            dense
            hide-details
            :suffix="$t('lbl.day')"
          ></v-select>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    model: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    methodPays: {
      type: Array,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      typesMethodPays: [],
      showMonto: false,
      showTime: false,
      times: [],
      showDeposit: false,
      showCredit: false,
      showOperaCont: false,
      showCompPay: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
      },
      loading: false,
      permisos: sessionStorage.getItem('permisos_auth'),
    }
  },
  created() {
    this.times.push({
      id: 7,
      name: 7,
    })
    this.times.push({
      id: 15,
      name: 15,
    })
    this.times.push({
      id: 30,
      name: 30,
    })

    if (this.model.method_pay_id) {
      this.typesMethodPays = this.model.method_pay.types
      if (this.model.method_pay.slug === 'credito') {
        if (this.model.type_method_pay_id) {
          const type = this.typesMethodPays.filter(e => e.id === this.model.type_method_pay_id)[0]
          this.changeTypeMethod(type)
        }
      }
    }
  },
  methods: {
    ...mapMutations(['deleteContactsAfiliados']),
    changeMethodPay(method) {
      if (method.types) {
        this.typesMethodPays = method.types
      }
      this.model.type_method_pay_id = null
    },
    changeTypeMethod(type) {
      if (type.slug === 'monto-de-dinero') {
        this.showMonto = true
        this.showTime = false
        this.model.data_method_pay.time = null
      } else if (type.slug === 'monto-y-tiempo') {
        this.showMonto = true
        this.showTime = true
      } else if (type.slug === 'tiempo') {
        this.showTime = true
        this.showMonto = false
        this.model.data_method_pay.monto = null
      }
    },
  },
}
</script>
<style scoped>
::v-deep .my-input .v-label,
::v-deep .my-input input {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
::v-deep .v-text-field__details {
  padding: 0 0 0 0;
}
</style>
