<template>
  <fragment>
    <v-row>
      <v-col
        cols="12"
        md="10"
      >
        <span v-if="!showRow || index !== pos">
          <fragment v-if="payMedio.payments === 'banco'">
            <span v-if="payMedio.moneda">
              {{ `${payMedio.moneda}-` }}
            </span>
            <span v-if="payMedio.name_banco">
              {{ payMedio.name_banco }}
            </span>
            <span v-if="payMedio.principal">
              <b>{{ ` (${$t('lbl.principal')})` }}</b>
            </span>
          </fragment>
          <fragment v-if="payMedio.payments === 'zelle'">
            <span v-if="payMedio.dataZelle.email">
              {{ `Email: ${payMedio.dataZelle.email}` }}
            </span>
            <span v-if="payMedio.dataZelle.phone">
              {{ `Cell: ${payMedio.dataZelle.phone}` }}
            </span>
            <span v-if="payMedio.principal">
              <b>{{ ` (${$t('lbl.principal')})` }}</b>
            </span>
          </fragment>
        </span>

        <v-radio-group
          v-if="showRow && index === pos"
          v-model="payMedio.payments_id"
          class="mt-0 pt-0"
          row
        >
          <v-col
            cols="12"
            md="3"
          ></v-col>
          <v-col
            v-for="(method, indEx) in payMents"
            :key="indEx"
            cols="12"
            md="2"
          >
            <!--:disabled="method.slug !== 'banco'"-->
            <v-card color="text-center">
              <v-card-text>
                <v-row>
                  <v-col
                    cols="1"
                    class="justify-center align-center mt-2"
                  >
                    <v-radio
                      label=""
                      :value="method.id"
                      @click="changePayMedio(method)"
                    ></v-radio>
                  </v-col>
                  <v-col cols="8">
                    <v-avatar
                      v-if="method.slug === 'zelle'"
                      tile
                    >
                      <Zelle :color="$vuetify.theme.themes.light.primary" />
                    </v-avatar>
                    <v-icon
                      v-else
                      size="3rem"
                      color="primary"
                    >
                      {{ method.icon }}
                    </v-icon>
                  </v-col>
                </v-row>
                <h2 class="mt-4">
                  {{ method.name }}
                </h2>
              </v-card-text>
            </v-card>
          </v-col>
        </v-radio-group>
      </v-col>
      <v-col
        cols="12"
        md="1"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              fab
              outlined
              v-bind="attrs"
              v-on="on"
              @click="showItem"
            >
              {{ showRow && index === pos ? icons.mdiMenuDown : icons.mdiMenuRight }}
            </v-icon>
          </template>
          <span>{{ showRow && index === pos ? $t('lbl.hidden') : $t('lbl.show') }}</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row v-if="showRow && index === pos && payMedio.payments === 'banco'">
      <v-col
        cols="12"
        md="6"
      >
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-text-field
              v-model="payMedio.name_fiscal_facturation"
              :label="$t('register.nameFact')"
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="8"
          >
            <v-text-field
              v-model="payMedio.no_cuenta_bancaria"
              :label="$t('register.noBanco')"
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="payMedio.moneda"
              :items="monedas"
              :label="$t('register.moneda')"
              outlined
              dense
              item-text="code"
              item-value="code"
              hide-details="auto"
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="8"
          >
            <v-text-field
              v-model="payMedio.iban"
              label="IBAN, IRC o CLABE"
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="payMedio.swift"
              :label="$t('register.codeSWIFT')"
              outlined
              dense
              hide-details="auto"
              :rules="swiftRules"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="8"
          ></v-col>

          <v-col
            cols="12"
            md="3"
          >
            <!-- :disabled="disabledRadio" -->
            <v-switch
              v-model="payMedio.principal"
              :label="$t('lbl.principal')"
              hide-details
              class="mt-0"
              :disabled="disablePrincipal"
            ></v-switch>
          </v-col>
          <v-col
            cols="12"
            md="1"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  dark
                  x-small
                  color="error"
                  v-bind="attrs"
                  v-on="on"
                  @click="deletePayMedio(pos)"
                >
                  <v-icon small>
                    {{ icons.mdiTrashCan }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('btn.delete') }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <v-row>
          <v-col
            cols="12"
            md="8"
          >
            <v-text-field
              v-model="payMedio.name_banco"
              :label="$t('register.nameBanco')"
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="payMedio.code_sucursal"
              :label="$t('register.codeSucursal')"
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <AddressBancaria
              v-for="(address, indA) in payMedio.address"
              :key="indA"
              :model="model"
              :address="address"
              :pos-medio="pos"
              :pos="indA"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="payMedio.code_postal"
              :label="$t('register.codePostal1')"
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="5"
          >
            <v-text-field
              v-model="payMedio.localidad"
              :label="$t('register.locality')"
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="payMedio.country"
              :items="countries"
              :label="$t('register.country')"
              item-text="name"
              item-value="name"
              outlined
              dense
              hide-details="auto"
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row v-if="showRow && index === pos && payMedio.payments === 'zelle'">
      <v-col
        cols="12"
        md="4"
      >
        <v-text-field
          v-model="payMedio.dataZelle.email"
          :label="$t('lbl.email')"
          outlined
          dense
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-text-field
          v-model="payMedio.dataZelle.phone"
          :label="$t('lbl.phoneUSA')"
          outlined
          dense
          hide-details="auto"
        ></v-text-field>
      </v-col>

      <v-col
        cols="12"
        md="2"
      >
        <!-- :disabled="disabledRadio" -->
        <v-switch
          v-model="payMedio.principal"
          :label="$t('lbl.principal')"
          hide-details
          class="mt-0"
          :disabled="disablePrincipal"
        ></v-switch>
      </v-col>
      <v-col
        cols="12"
        md="1"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              dark
              x-small
              color="error"
              v-bind="attrs"
              v-on="on"
              @click="deletePayMedio(pos)"
            >
              <v-icon small>
                {{ icons.mdiTrashCan }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('btn.delete') }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-divider class="mt-2 mb-2"></v-divider>
  </fragment>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import Zelle from '@/views/utils/images/zelle.vue'
import { mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
} from '@mdi/js'
import AddressBancaria from './AddressBancaria.vue'

export default {
  components: {
    AddressBancaria,
    Zelle,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    model: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    payMedio: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    payMedios: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    payMents: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    monedas: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    countries: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    index: {
      type: Number,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      typesMethodPays: [],
      showMonto: false,
      showTime: false,
      times: [],
      monto: null,
      time: null,
      showDeposit: false,
      showCredit: false,
      showOperaCont: false,
      showCompPay: false,
      showRow: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
      },
      loading: false,
      permisos: sessionStorage.getItem('permisos_auth'),
      swiftRules: [
        // v => !!v || 'Campo requerido',

        // v => v.length <= 8 || 'Swift no válido',
        v => /[0-9a-zA-Z]{8}/.test(v) || 'Swift no válido',
      ],
    }
  },
  computed: {
    disabledRadio() {
      let tienePrincipal = false
      this.payMedios.forEach(element => {
        if (element.principal) {
          tienePrincipal = true
        }
      })

      let disabled = true
      if (tienePrincipal) {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.payMedios.length; index++) {
          const element = this.payMedios[index]

          if (element.principal && element.id === this.payMedio.id && this.pos === index) {
            disabled = false
          }
        }
      } else {
        disabled = false
      }

      return disabled
    },
    disablePrincipal() {
      let disabled = false

      if (this.payMedio.payments === 'banco') {
        if (
          !this.payMedio.name_fiscal_facturation
          || !this.payMedio.no_cuenta_bancaria
          || !this.payMedio.moneda
          || !this.payMedio.iban
          || !this.payMedio.swift
          || !this.payMedio.name_banco
          || this.payMedio.address.length === 0
          || !this.payMedio.code_postal
          || !this.payMedio.localidad
          || !this.payMedio.country
        ) {
          disabled = true
        }
      } else if (this.payMedio.payments === 'zelle') {
        if (!this.payMedio.dataZelle.email && !this.payMedio.dataZelle.phone) {
          disabled = true
        }
      }

      return disabled
    },
  },
  created() {
    if (this.pos === 0 && this.payMedios.length === 1) {
      this.showRow = true
      this.$emit('setIndex', this.pos)
    } else if (this.payMedio.payments === 'banco') {
      if (!this.payMedio.name_banco) {
        this.showRow = true
        this.$emit('setIndex', this.pos)
      }
    } else if (this.payMedio.payments === 'zelle') {
      if (!this.payMedio.dataZelle.email && !this.payMedio.dataZelle.phone) {
        this.showRow = true
        this.$emit('setIndex', this.pos)
      }
    }

    /* if (this.payMedios.length === 1) {
      this.payMedio.principal = true
    } else */ if (
      this.payMedio.principal
    ) {
      this.showRow = true
      this.$emit('setIndex', this.pos)
    }
  },
  methods: {
    ...mapMutations(['deleteContactsAfiliados']),
    setShowRow() {
      this.$nextTick(() => {
        this.showRow = !this.showRow
        this.$emit('setIndex', this.pos)
      })
    },
    showItem() {
      if (this.index === this.pos) {
        this.showRow = !this.showRow
      } else if (!this.showRow) {
        this.showRow = !this.showRow
      }
      this.$emit('setIndex', this.pos)
    },
    deletePayMedio(pos) {
      this.model.pay_medios.splice(pos, 1)
    },
    changePayMedio(method) {
      this.payMedio.payments = method.slug
    },
  },
}
</script>
<style scoped>
::v-deep .my-input .v-label,
::v-deep .my-input input {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
::v-deep .v-text-field__details {
  padding: 0 0 0 0;
}
</style>
